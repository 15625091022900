import { JsonPipe, NgClass } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { SOCIAL_ICON_ENUM } from '@app/enums/social-icon';
import { SocialIcon } from '@app/models/social-icon.model';
import { ModalGralPipe } from '@app/pipes/modal-gral/modal-gral.pipe';
import { PostService } from '@app/providers/post/post.service';
import { ProfileService } from '@app/providers/profile/profile.service';
import { OpenModalService } from '@app/services/open-modal/open-modal.service';
import { ScrollHomeService } from '@app/services/scroll-home/scroll-home.service';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-share-link',
    standalone: true,
    imports: [NgClass, JsonPipe],
    templateUrl: './share-link.component.html',
    styleUrl: './share-link.component.sass',
})
export class ShareLinkComponent {
    @Input() icons: SocialIcon[];
    @Input() documentId: string;
    @Input() type: 'home' | 'profile' | 'concept';
    @Input() border: boolean;

    modalGralPipe: ModalGralPipe;

    private postHttpService = inject(PostService);
    private profileHttpService = inject(ProfileService);
    private windowsSizeService = inject(WindowsSizeService);
    private openModalService = inject(OpenModalService);
    private readonly scrollHomeService = inject(ScrollHomeService);

    constructor() {
        this.modalGralPipe = new ModalGralPipe();
        this.type = 'home';
        this.documentId = '';
        this.border = false;
        this.icons = [];
    }

    sentData(res: SocialIcon): void {
        if (!res.click) return;
        if (!this.openModalService.openModalLogin()) return;

        if (res.name === 'heart') this.sentLike();
        else if (res.name === 'whatsapp' || res.name === 'facebook')
            this.sentShare(res);
    }

    sentLike(): void {
        let requestLike: Observable<any> = new Observable();

        switch (this.type) {
            case 'home':
                requestLike = this.postHttpService.sentPostBoxLike(
                    this.documentId
                );
                break;

            case 'profile':
                requestLike = this.profileHttpService.sentLike(this.documentId);
                break;

            case 'concept':
                requestLike = this.postHttpService.sentLike(this.documentId);
                break;
        }

        requestLike.subscribe(() => {
            this.scrollHomeService.resetValues();

            this.icons = this.icons.map((item: SocialIcon) => {
                if (item.name === 'heart') {
                    return {
                        ...item,
                        icon: item.icon.includes('heart-full.png')
                            ? SOCIAL_ICON_ENUM.heartEmpty
                            : SOCIAL_ICON_ENUM.heartFill,
                        quantity: item.icon.includes('heart-full.png')
                            ? item.quantity - 1
                            : item.quantity + 1,
                    };
                }
                return item;
            });
        });
    }

    sentShare({ url, message, name }: SocialIcon): void {
        let requestShare: Observable<any> = new Observable();

        switch (this.type) {
            case 'home':
                requestShare = this.postHttpService.sentPostBoxShare(
                    this.documentId
                );
                break;

            case 'profile':
                requestShare = this.profileHttpService.sentShare(
                    this.documentId
                );
                break;

            case 'concept':
                requestShare = this.postHttpService.sumShare({
                    publicationId: this.documentId,
                    typeShare: name,
                });
                break;
        }

        requestShare.subscribe(() => {
            this.scrollHomeService.resetValues();
            this.icons = this.icons.map((item: SocialIcon) => {
                if (item.name !== 'heart') {
                    return {
                        ...item,
                        quantity: item.quantity + 1,
                    };
                }
                return item;
            });

            if (
                'share' in navigator &&
                typeof navigator.share === 'function' &&
                this.windowsSizeService.checkMaxScreenSize(650)
            ) {
                navigator
                    .share({
                        title: 'Santo Vecino',
                        text: message,
                        url,
                    })
                    .catch(error => console.error('Error sharing:', error));
            } else {
                window.open(url, '_blank');
            }
        });
    }
}
