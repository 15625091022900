<!--CHECK-->
<div class="container">
    @for (itrData of icons; track itrData) {
        <button
            (click)="sentData(itrData)"
            [class]="itrData.click ? 'card cursor-pointer' : 'card'">
            <img
                class="icon-shared"
                [src]="modalGralPipe.transform(itrData.icon, 'img-empty')"
                [alt]="itrData.name" />

            @if (itrData.text) {
                <div class="text_black">
                    {{ itrData.quantity }}
                </div>
            }
        </button>
    }
</div>
